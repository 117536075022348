import { keys, makeAutoObservable, observable, runInAction, values } from "mobx";
import { CoreApiEvent, CoreApiPartnerActivity, CoreApiRecord, CoreApiResponse } from "@domain/jrg-core-api";
import { computedFn, fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { SrmApiActivity, SrmApiSchedule } from "@domain/jrg-srm-api";
import { CompositeTalk } from "@domain/local-composite-talk";
import { isResponse } from "@utils/type-utils";

export class JrgEventsStore {
    eventsByIdPromise?: IPromiseBasedObservable<CoreApiEvent[]> = undefined;
    eventsById = observable.map<string, CoreApiEvent>();
    eventPromise?: IPromiseBasedObservable<CoreApiEvent> = undefined;
    activitiesPromise?: IPromiseBasedObservable<SrmApiActivity[]> = undefined;
    partnersActivitiesPromise?: IPromiseBasedObservable<CoreApiPartnerActivity[]> = undefined;
    // partnersActivitiesPromise?: IPromiseBasedObservable<CoreApiPartnerActivityResponse> = undefined;
    schedulePromise?: IPromiseBasedObservable<SrmApiSchedule> = undefined;
    talksPromisesById = observable.map<string, IPromiseBasedObservable<CompositeTalk>>();

    constructor() {
        makeAutoObservable(this);
    }

    loadAllEvents() {
        this.eventsByIdPromise = fromPromise(
            fetch("/api/jrg-core/events")
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Can't load events. Status ${response.status}`);
                    }
                    return response.json();
                })
                .then((value) => {
                    const response = value as CoreApiResponse;
                    runInAction(() => {
                        response.data.forEach((event) => {
                            this.eventsById.set(event.id, event);
                        });
                    });
                    return response.data;
                })
        );
    }

    loadEvent(eventId: string) {
        this.eventPromise = fromPromise(
            fetch(`/api/jrg-core/events/${eventId}`).then<CoreApiEvent>((response) => response.json())
        );
    }

    loadActivities(eventId: string) {
        const origPromise = fetch(`/api/jrg-srm/events/${eventId}/activities`)
            .then<SrmApiActivity[]>((response) => {
                if (!response.ok) {
                    throw new Error(`Can't load activities. Status ${response.status}`);
                }
                return response.json();
            })
            .then((value) => {
                value.forEach((activity) => this.loadSpeechAndEnhanceByActivity(activity));
                return value;
            });
        this.activitiesPromise = fromPromise(origPromise);

        return origPromise;
    }

    loadSpeechAndEnhanceByActivity(activity: SrmApiActivity) {
        const activityId = activity.data.id.toString();
        const origPromise = this.loadTalkByActivityId(activityId)
            .catch((info) => {
                if (isResponse(info)) {
                    return info.text();
                }
                return "no details";
            })
            .then((value) => {
                return {
                    liveSpeech: typeof value === "string" ? { error: true, message: value } : value,
                    srmActivity: activity,
                };
            });
        this.talksPromisesById.set(activityId, fromPromise(origPromise));
    }

    /**
     * Load activity details from live 2.0
     * @param activityId
     */
    loadTalkByActivityId(activityId: string) {
        const origPromise = fetch(`/api/jrg-backend-live/speeches/${activityId}`)
            .then(async (response) => {
                if (!response.ok) {
                    console.error(`[jrg-events-store] Can't load speech. Status ${response.status}`);
                    return Promise.reject(response);
                }
                return response.json();
            })
            .then((value) => {
                return value.speech;
            });

        return origPromise;
    }

    loadPartners(eventId: string) {
        this.partnersActivitiesPromise = fromPromise(
            fetch(`/api/jrg-core/events/${eventId}/partners`)
                .then((response) => response.json())
                .then<CoreApiPartnerActivity[]>((data) => {
                    return data.data.map((item: CoreApiRecord<CoreApiPartnerActivity>) => item.data);
                })
        );
    }

    loadPublicSchedule(eventId: string) {
        this.schedulePromise = fromPromise(
            fetch(`https://core.jugru.team/api/v1/public/events/${eventId}/schedule`)
                .then((res) => {
                    if (!res.ok) {
                        return Promise.reject("Can't load SRM events");
                    }
                    return res;
                })
                .then((response) => response.json())
                .then<SrmApiSchedule>((value) => value.data)
        );
    }

    loadSchedule(eventId: string) {
        this.schedulePromise = fromPromise(
            fetch(`/api/jrg-srm/events/${eventId}/schedule`)
                .then((res) => {
                    if (!res.ok) {
                        return Promise.reject("Can't load SRM events");
                    }
                    return res;
                })
                .then<SrmApiSchedule>((response) => response.json())
        );
    }

    get event() {
        if (this.eventPromise?.state !== "fulfilled") {
            return undefined;
        }
        return this.eventPromise?.value;
    }

    get activities() {
        if (this.activitiesPromise?.state !== "fulfilled") {
            return undefined;
        }
        return this.activitiesPromise?.value;
    }

    get partnersActivities() {
        if (this.partnersActivitiesPromise?.state !== "fulfilled") {
            return [];
        }
        return this.partnersActivitiesPromise?.value ?? [];
    }

    get talksIds(): readonly string[] {
        return keys(this.talksPromisesById) as string[];
    }

    get talksCount() {
        return this.talksPromisesById.size;
    }

    get talks() {
        return values(this.talksPromisesById);
    }

    get isAllTalksLoaded() {
        if (this.talks.length === 0) return false;
        return this.talks.find((value) => value.state !== "fulfilled") === undefined;
    }

    get isAllPartnersLoaded(): boolean {
        return !(this.partnersActivities === undefined || this.partnersActivities?.length === 0);
    }

    get isAllTalksHasLiveSpeech() {
        return this.talks.every((value) => value.state === "fulfilled" && value.value.liveSpeech);
    }

    get eventsGroupedBySeason() {
        const events = values(this.eventsById);
        return events.reduce((accumulator, current) => {
            const seasonName = current.season?.name?.en ?? "No season";
            (accumulator[seasonName] = accumulator[seasonName] || []).push(current);
            return accumulator;
        }, {} as Record<string, CoreApiEvent[]>);
    }

    clearCompositeTalks() {
        this.talksPromisesById.clear();
    }

    findCompositeTalkPromiseById = computedFn(
        (id: string) => {
            return this.talksPromisesById.get(id);
        },
        { name: "findCompositeTalkById", keepAlive: true }
    );
}
