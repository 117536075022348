import "../styles/globals.css";
import "../styles/globals.module.scss";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";

import { configure } from "mobx";
import { getPublicConfiguration } from "@config/universal-config";
import { enableStaticRendering } from "mobx-react-lite";
import { withJrgEventsStore } from "../hooks/jrg-events-store-hooks";
import { ErrorBoundary } from "@components/jrg/error-boundary";

enableStaticRendering(typeof window === "undefined");

const { isProduction } = getPublicConfiguration();

configure({
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: true,
    disableErrorBoundaries: !isProduction,
});

const MyApp = ({ Component, pageProps }: AppProps) => {
    return (
        <SessionProvider session={pageProps.session}>
            <ErrorBoundary message="Unhandled error">
                <Component {...pageProps} />
            </ErrorBoundary>
        </SessionProvider>
    );
};

export default withJrgEventsStore(MyApp);
