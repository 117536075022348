import { FC } from "react";
import { JrgEventsStore } from "../data/jrg-events-store";
import { createStrictContext } from "./create-strict-context";

const [ContextJrgEventsStoreProvider, useJrgEventsStore] = createStrictContext<JrgEventsStore>({
    name: "ContextEventsStore",
    errorMessage: "Decorate withCallManager or similar before call useCall* functions",
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export function withJrgEventsStore(Component: FC<any>) {
    const store = new JrgEventsStore();

    const withJrgEventsStoreWrapper: FC<unknown> = (props: unknown) => {
        return (
            <ContextJrgEventsStoreProvider value={store}>
                <Component {...props} />
            </ContextJrgEventsStoreProvider>
        );
    };

    return withJrgEventsStoreWrapper;
}

export { useJrgEventsStore };
