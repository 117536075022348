import getConfig from "next/config";
import { PublicConfiguration, ServerRuntimeConfiguration } from "./config";

/**
 * Use it for client side code.
 */
function getPublicConfiguration(): PublicConfiguration {
    const { publicRuntimeConfig } = getConfig() ?? {
        publicRuntimeConfig: {},
    };
    return publicRuntimeConfig as PublicConfiguration;
}

/**
 * Use it for server side code. Appropriate place for some secrets.
 */
function getServerRuntimeConfiguration(): ServerRuntimeConfiguration {
    const nextConfig = getConfig();
    const { serverRuntimeConfig } = nextConfig ?? {
        serverRuntimeConfig: {},
    };

    return serverRuntimeConfig as ServerRuntimeConfiguration;
}

export { getServerRuntimeConfiguration, getPublicConfiguration };
