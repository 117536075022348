import { Component, ErrorInfo, FC, PropsWithChildren } from "react";

export type ErrorBoundaryState = { hasError: boolean; error?: unknown };
export type ErrorBoundaryProps = PropsWithChildren<unknown> & {
    message?: string;
    wrap?: FC<Omit<ErrorBoundaryProps, "wrap"> & ErrorBoundaryState>;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }

    static getDerivedStateFromError(_error: unknown) {
        return { hasError: true, error: _error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            const container = this.props.wrap;
            if (container) {
                return container({ ...this.props, ...this.state });
            }
            return (
                <p>
                    <span>Something went wrong. {this.props.message}</span>
                    <span>{typeof this.state.error === "object" ? this.state.error?.toString() : "Unknown"}</span>
                </p>
            );
        }

        return this.props.children;
    }
}
